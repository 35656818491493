import PATH from '@models/constants/Path';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyAppVersionPage = lazy(
  () => import('@pages/dashboard/appVersion/AppVersionPage.adapter'),
);

const dashboardRoute: Route = {
  component: LazyAppVersionPage,
  hideInSidebar: true,
  id: 'app-version',
  path: PATH.AppVersion.Detail,
  title: 'route.dashboard.title',
};

export default dashboardRoute;
