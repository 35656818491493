import { ThemeColors, ThemeImages } from '@appconf/models';
import LogoImage from '@assets/images/zug/Logo.svg';
import { ThemeConfig as AntThemeConfig } from 'antd';

export const zugThemeColors: ThemeColors = {
  background: '#f0f2f5',
  componentBackground: '#ffffff',
  neutral: '#ffffff',
  onPrimary: '#ffffff',
  onPrimaryVariant: '#ffffff',
  orgAdminHighlight: 'red',
  orgHighlight: 'orange',
  primary: '#000000',
  primaryVariant: '#40a9ff',
  text: 'rgba(0, 0, 0, 0.85)',
};

export const zugThemeConfig: AntThemeConfig = {
  components: {
    Badge: {
      colorBgContainer: 'rgba(0, 0, 0, 0.45)',
      listCountBadgeColor: '#f0f0f0',
    } as any,
    Button: {
      colorPrimaryHover: '#beff64',
      colorTextLightSolid: '#000000',
      defaultHoverBg: '#beff64',
      defaultHoverBorderColor: '#beff64',
      defaultHoverColor: '#000000',
      primaryColor: '#ffffff',
    },
    Descriptions: {
      colorSplit: '#f0f0f0',
    },
    Dropdown: {
      colorPrimaryBg: '#f0fbff',
    },
    Menu: {
      itemSelectedBg: '#f5f5f5',
    },
    Select: {
      optionSelectedBg: '#f0fbff',
    },
    Steps: {
      finishIconBorderColor: '#000000',
    },
    Table: {
      rowHoverBg: '#fafafa',
      rowSelectedBg: '#f0fbff',
      rowSelectedHoverBg: '#e6f8ff',
    },
  },
  token: {
    borderRadius: 2,
    colorBgBase: '#f0f2f5',
    colorBgContainer: '#ffffff',
    colorBgElevated: '#ffffff',
    colorBgLayout: '#f0f2f5',
    colorInfo: '#000000',
    colorLink: '#40a9ff',
    colorPrimary: '#000000',
    colorPrimaryTextHover: '#000000',
    colorSplit: '#f0f0f0',
    colorText: 'rgba(0, 0, 0, 0.85)',
    controlItemBgActive: '#f5f5f5',
    controlItemBgActiveHover: '#f0f2f5',
    controlItemBgHover: '#f0f2f5',
    controlOutline: 'rgba(0, 0, 0, 0.1)',
  },
};

export const zugThemeImages: ThemeImages = {
  logoURIs: [LogoImage],
};
