import { QUERY_STALE_TIME } from '@models/constants/API';
import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: QUERY_STALE_TIME,
    },
  },
});

export default queryClient;
