/* istanbul ignore file */
import appConfig from '@appconf/config';
import { LANGUAGE_STORAGE_KEY } from '@models/constants/Storage';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  // load translation using http from "public/locales" folder
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: (lngs: string[], namespaces: string[]) => {
        const pathHash = process.env.REACT_BUILD_HASH
          ? `/${process.env.REACT_BUILD_HASH}`
          : '';
        const pathPrefix =
          `${process.env.REACT_BASE_PATH ?? ''}/locales` + pathHash;
        // Map "flavor" namespace to app flavor translation
        if (namespaces[0] === 'flavor') {
          return `${pathPrefix}/{{lng}}/${appConfig.flavor}.json`;
        }
        return `${pathPrefix}/{{lng}}/{{ns}}.json`;
      },
    },
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'flavor',
    detection: {
      lookupLocalStorage: LANGUAGE_STORAGE_KEY,
    },
    fallbackLng: 'en',
    fallbackNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: 'languageOnly',
    ns: ['flavor', 'translation'],
    supportedLngs: ['de', 'en'],
  });

export default i18n;
