import { ScanOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyProofRequestListPage = lazy(
  () =>
    import(
      '@pages/dashboard/proofRequest/ProofRequestListPage/ProofRequestListPage.adapter'
    ),
);

const LazyProofRequestCreatePage = lazy(
  () =>
    import(
      '@pages/dashboard/proofRequest/ProofRequestCreatePage/ProofRequestCreatePage.adapter'
    ),
);

const LazyProofRequestDetailsPage = lazy(
  () =>
    import(
      '@pages/dashboard/proofRequest/ProofRequestDetailsPage/ProofRequestDetailsPage.adapter'
    ),
);

const proofRequestsRoute: Route = {
  children: [
    {
      backPath: PATH.ProofRequest.List,
      component: LazyProofRequestCreatePage,
      id: 'proof-request-create',
      path: PATH.ProofRequest.Create(),
      requiredPermission: OrganisationPermission.ProofIssue,
      title: 'route.proofRequestCreate.title',
    },
    {
      component: LazyProofRequestDetailsPage,
      id: 'proof-request-details',
      path: PATH.ProofRequest.Detail(':proofRequestId'),
      requiredPermission: OrganisationPermission.ProofDetail,
    },
  ],
  component: LazyProofRequestListPage,
  hideInSidebar: false,
  iconComponent: ScanOutlined,
  id: 'proof-request',
  path: PATH.ProofRequest.List,
  requiredPermission: OrganisationPermission.ProofList,
  title: 'route.proofRequests.title',
};

export default proofRequestsRoute;
