import { AppConfig } from './models';

/*
 * Export App Flavor based config
 * NOTE: Read "Environments And Flavors" in README.md for more details.
 */
const appConfigMap: Record<string, AppConfig> = {
  procivis: require('./procivis/config').default,
  procivisDev: require('./procivisDev/config').default,
  zug: require('./zug/config').default,
};

const appConfig =
  appConfigMap[process.env.REACT_APP_TARGET_FLAVOR || 'procivis'];

export default appConfig;
