export type FetchDefaultOptions = {
  cache: RequestCache;
  credentials: RequestCredentials;
  headers: HeadersInit;
  mode: RequestMode;
  redirect: RequestRedirect;
  referrerPolicy: ReferrerPolicy;
};

export interface CommonApiOptions {
  checkTokenValidity?: boolean;
  emitErrorEvent?: boolean;
  headers?: HeadersInit & AuthorizationHeaders;
  includeAuth?: boolean;
  returnJson?: boolean; // Should be used for fetching errors. Errors on mutation should be handled in place of use
}

export type QueryParamsSingleValue = string | number | undefined;
export type QueryParamsValue =
  | QueryParamsSingleValue
  | Array<QueryParamsSingleValue>;
export type QueryParams = Record<string, QueryParamsValue>;

export interface GETOptions extends CommonApiOptions {
  queryParams?: QueryParams;
}

export type AuthorizationHeaders = {
  Authorization?: string;
};

export class ResponseError extends Error {
  public readonly response: Response;
  constructor(response: Response) {
    super(`ResponseError (${response.status}): ${response.statusText}`);
    this.response = response;
  }
}

export class AuthenticationError extends Error {}

export type ApiMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
