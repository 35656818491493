import { FullScreenView } from '@procivis/react-components';
import AppOutlet from '@router/outlets/App';
import React, { FC } from 'react';

export interface AppProps {
  flavor?: string;
}

const App: FC<AppProps> = () => {
  return (
    <FullScreenView>
      <AppOutlet />
    </FullScreenView>
  );
};

export default App;
