import { ORGANISATION_STORAGE_KEY } from '@models/constants/Storage';
import {
  OrganisationStore,
  OrganisationStoreModel,
  OrganisationStoreSnapshot,
  OrganisationStoreSnapshotIn,
} from '@models/store/organisation/OrganisationStore';
import { StoreEnv } from '@store/Environment';
import { getPersistedData, persistData } from '@store/Storage';
import { onSnapshot } from 'mobx-state-tree';

export const setupOrganizationStore = (env: StoreEnv) => {
  let organizationStore: OrganisationStore;
  const initData = {
    currentOrganizationId: undefined,
  };

  try {
    const storedOrganizationData = getPersistedData<
      Partial<OrganisationStoreSnapshotIn>
    >(ORGANISATION_STORAGE_KEY);
    const mergedData = { ...initData, ...storedOrganizationData };

    organizationStore = OrganisationStoreModel.create(mergedData, env);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Couldn't load persistent organization state", e);
    organizationStore = OrganisationStoreModel.create(initData, env);
  }

  onSnapshot(organizationStore, (snapshot) => {
    const persistableData: Partial<OrganisationStoreSnapshot> = {
      currentOrganisationId: snapshot.currentOrganisationId,
    };

    persistData<Partial<OrganisationStoreSnapshot>>(
      persistableData,
      ORGANISATION_STORAGE_KEY,
    );
  });

  return organizationStore;
};
