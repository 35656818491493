import ErrorPageWrapper from '@components/LoadingError/ErrorPageWrapper';
import PATH from '@models/constants/Path';
import GuardedElement from '@router/GuardedElement';
import { getMainPageOutletRoutes } from '@utils/routing';
import React, { FC, lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

const LazyDashboardPage = lazy(
  () => import('@pages/dashboard/DashboardPage.adapter'),
);
const LazyAdaptedLoginPage = lazy(
  () => import('@pages/login/LoginPage.adapter'),
);
const LazySetPasswordPage = lazy(
  () => import('@pages/password/SetPasswordPage/SetPasswordPage.adapter'),
);
const LazyAdaptedForgotPasswordPage = lazy(
  () => import('@pages/password/ForgotPassword/ForgotPasswordPage.adapter'),
);

export interface AppOutletProps {}

const AppOutlet: FC<AppOutletProps> = () => {
  return (
    <Routes>
      <Route element={<LazyAdaptedLoginPage />} path={PATH.Auth.Login} />
      <Route element={<LazySetPasswordPage />} path={PATH.Auth.SetPassword} />
      <Route
        element={<LazyAdaptedForgotPasswordPage />}
        path={PATH.Auth.ForgotPassword}
      />
      <Route
        element={
          <GuardedElement>
            <ErrorPageWrapper>
              <LazyDashboardPage>
                <Outlet />
              </LazyDashboardPage>
            </ErrorPageWrapper>
          </GuardedElement>
        }
      >
        {getMainPageOutletRoutes()}
      </Route>
    </Routes>
  );
};

export default AppOutlet;
