import appConfig from '@appconf/config'; // REQUIRED! HAS to be imported AND used in this file!
import * as Sentry from '@sentry/react';

export const isSentryAvailable =
  process.env.NODE_ENV === 'production' && !!appConfig.sentry.dsn;

export const initSentry = () => {
  if (isSentryAvailable) {
    Sentry.init({
      autoSessionTracking: false,
      debug: process.env.NODE_ENV === 'development',
      dsn: appConfig.sentry.dsn,
      environment: `${appConfig.flavor}_${window.location.hostname}`,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
      ],
      release: `${appConfig.flavor}@${appConfig.release}`,
    });
  }
};
