import LoadingErrorPage from '@components/LoadingError/LoadingErrorPage';
import { useRootStore } from '@contexts/RootStore/RootStoreContext';
import { Event } from '@models/constants/Events';
import { AuthenticationError, ResponseError } from '@models/types/Api';
import AccessDeniedPage from '@pages/dashboard/accessDenied/AccessDeniedPage';
import EventBus from '@utils/events';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

enum ErrorType {
  FORBIDDEN_ACCESS,
  GENERIC_ERROR,
}

const ErrorPageWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [errorType, setErrorType] = useState<ErrorType>();
  const rootStore = useRootStore();
  const navigate = useNavigate();

  // reset error when URL changes
  const location = useLocation();
  useEffect(() => {
    setErrorType(undefined);
  }, [location]);

  const requestErrorHandler = useCallback(
    (requestError: unknown) => {
      if (requestError instanceof AuthenticationError) {
        if (location.pathname !== '/login') {
          rootStore.logout();
          navigate('/login', {
            state: { detail: 'Invalid token', errorCode: 401 },
          });
        }
      } else if (requestError instanceof ResponseError) {
        if (requestError.response.status === 403) {
          setErrorType(ErrorType.FORBIDDEN_ACCESS);
          return;
        }
      }

      setErrorType(ErrorType.GENERIC_ERROR);
    },
    [location.pathname, navigate, rootStore],
  );

  useEffect(() => {
    EventBus.clearListeners(Event.REQUEST_ERROR);
    const unsubscribe = EventBus.on(Event.REQUEST_ERROR, requestErrorHandler);
    return () => unsubscribe();
  }, [requestErrorHandler]);

  switch (errorType) {
    case ErrorType.FORBIDDEN_ACCESS:
      return <AccessDeniedPage />;
    case ErrorType.GENERIC_ERROR:
      return <LoadingErrorPage />;
    default:
      return <>{children}</>;
  }
};

export default ErrorPageWrapper;
