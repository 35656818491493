import { ServerDisplay } from '@models/types/ServerConfig';
import { InitOptions } from 'i18next';

export const i18nInitialization = (i18nInstance: any) => {
  return new Promise<InitOptions>((resolve) => {
    if (!i18nInstance.isInitialized) {
      i18nInstance.on('initialized', (options: InitOptions) => {
        resolve(options);
      });
    } else {
      resolve(i18nInstance.options);
    }
  });
};

export const getTranslatedValueFromServerConfig = (
  value: ServerDisplay | undefined,
  translate: (key: string) => string,
  currentLanguage: string,
): string => {
  if (value === undefined || value === null) {
    return '';
  } else if (typeof value === 'string') {
    // value is a key to a translation
    return translate(`config.${value}`);
  } else {
    // value is a map: languageKey -> translation
    return value[currentLanguage];
  }
};
