import { useRootStore } from '@contexts/RootStore/RootStoreContext';
import LazyRouteElement from '@router/LazyRouteElement';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface GuardedElementProps extends PropsWithChildren {}

const GuardedElement: FC<GuardedElementProps> = observer(({ children }) => {
  const location = useLocation();
  const { networkStore } = useRootStore();
  const [isLoggedIn, setIsLoggedIn] = useState(networkStore.isLoggedIn);

  useEffect(
    () =>
      autorun(() => {
        if (networkStore.isLoggedIn !== isLoggedIn) {
          setIsLoggedIn(networkStore.isLoggedIn);
        }

        if (!networkStore.isLoggedIn) {
          networkStore.logout();
        }
      }),
    [networkStore, isLoggedIn],
  );

  return (
    <LazyRouteElement>
      {isLoggedIn ? (
        children
      ) : (
        <Navigate state={{ from: location }} to="/login" />
      )}
    </LazyRouteElement>
  );
});

export default GuardedElement;
