import PATH from '@models/constants/Path';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyProfileDetailsPage = lazy(
  () => import('@pages/dashboard/profile/ProfileDetailsPage.adapter'),
);

const profileRoute: Route = {
  backPath: PATH.Root,
  children: [],
  component: LazyProfileDetailsPage,
  hideInSidebar: true,
  id: 'my-account',
  path: PATH.Profile.Detail,
  title: 'route.myAccount.title',
};

export default profileRoute;
