/* istanbul ignore file */
import { RootStoreModel } from '@models/store/RootStoreModel';
import { setupApiConfigStore } from '@store/apiConfig/ApiConfigStore';
import { setupLanguageStore } from '@store/language/LanguageStore';
import { setupNetworkStore } from '@store/network/NetworkStore';
import { setupOrganizationStore } from '@store/organisation/OrganisationStore';

import { getStoreEnv } from './Environment';

export const setupRootStore = () => {
  const storeEnv = getStoreEnv();
  return RootStoreModel.create(
    {
      apiConfigStore: setupApiConfigStore(),
      languageStore: setupLanguageStore(storeEnv),
      networkStore: setupNetworkStore(),
      organisationStore: setupOrganizationStore(storeEnv),
    },
    storeEnv,
  );
};

const RootStore = setupRootStore();
export default RootStore;
