import { WarningOutlined } from '@ant-design/icons';
import { Card, Space } from '@procivis/react-components';
import { Typography as AntTypography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './LoadingErrorPage.module.css';

const LoadingErrorPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      title={
        <Space size="small">
          <WarningOutlined className={styles.icon} />
          {t('common.error.loadingError.title')}
        </Space>
      }
    >
      <AntTypography.Text>
        {t('common.error.loadingError.description')}
      </AntTypography.Text>
    </Card>
  );
};

export default LoadingErrorPage;
