import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const NetworkStoreModel = types
  .model('NetworkStore', {
    authToken: types.maybe(types.string),
    authTokenExpirationTimestamp: types.maybe(types.number),
    loginTimestamp: types.maybe(types.number),
  })
  // Sync Actions
  .actions((self) => ({
    logout: () => {
      self.authToken = undefined;
      self.authTokenExpirationTimestamp = undefined;
    },
    setAuthToken: (authToken: string, expirationTimestamp: number) => {
      self.authToken = authToken;
      self.authTokenExpirationTimestamp = expirationTimestamp;
      self.loginTimestamp = Date.now();
    },
  }))
  // Derived values
  .views((self) => ({
    get isLoggedIn() {
      return !!self.authToken;
    },
  }));

export interface NetworkStore extends Instance<typeof NetworkStoreModel> {}
export interface NetworkStoreSnapshot
  extends SnapshotOut<typeof NetworkStoreModel> {}
export interface NetworkStoreSnapshotIn
  extends SnapshotIn<typeof NetworkStoreModel> {}
