import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const OrganisationStoreModel = types
  .model('OrganisationStore', {
    currentOrganisationId: types.maybe(types.string),
  })
  // Sync Actions
  .actions((self) => ({
    changeOrganization: (newOrganisationId: string) => {
      if (self.currentOrganisationId !== newOrganisationId) {
        self.currentOrganisationId = newOrganisationId;
      }
    },
    logout: () => {
      self.currentOrganisationId = undefined;
    },
  }));

export interface OrganisationStore
  extends Instance<typeof OrganisationStoreModel> {}
export interface OrganisationStoreSnapshotIn
  extends SnapshotIn<typeof OrganisationStoreModel> {}
export interface OrganisationStoreSnapshot
  extends SnapshotOut<typeof OrganisationStoreModel> {}
