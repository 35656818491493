import PATH from '@models/constants/Path';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyRootPagePage = lazy(
  () => import('@pages/dashboard/rootPage/RootPage'),
);

const rootRoute: Route = {
  component: LazyRootPagePage,
  hideInSidebar: true,
  id: 'root-page',
  path: PATH.Root,
};

export default rootRoute;
