import { MonitorOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyProofSchemaListPage = lazy(
  () =>
    import(
      '@pages/dashboard/proofSchema/ProofSchemaListPage/ProofSchemaListPage.adapter'
    ),
);

const LazyProofSchemaCreatePage = lazy(
  () =>
    import(
      '@pages/dashboard/proofSchema/ProofSchemaCreatePage/ProofSchemaCreatePage.adapter'
    ),
);

const LazyProofSchemaDetailPage = lazy(
  () =>
    import(
      '@pages/dashboard/proofSchema/ProofSchemaDetailPage/ProofSchemaDetailPage.adapter'
    ),
);

const proofSchemasRoute: Route = {
  children: [
    {
      component: LazyProofSchemaDetailPage,
      id: 'proof-schema-detail',
      path: PATH.ProofSchema.Detail(':proofSchemaId'),
      requiredPermission: OrganisationPermission.ProofSchemaDetail,
    },
    {
      backPath: PATH.ProofSchema.List,
      component: LazyProofSchemaCreatePage,
      id: 'proof-schema-create',
      path: PATH.ProofSchema.Create,
      requiredPermission: OrganisationPermission.ProofSchemaCreate,
      title: 'route.proofSchemasCreate.title',
    },
  ],
  component: LazyProofSchemaListPage,
  hideInSidebar: false,
  iconComponent: MonitorOutlined,
  id: 'proof-schema',
  path: PATH.ProofSchema.List,
  requiredPermission: OrganisationPermission.ProofSchemaList,
  title: 'route.proofSchemas.title',
};

export default proofSchemasRoute;
