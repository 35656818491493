import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyAccessDeniedPage = lazy(
  () => import('@pages/dashboard/accessDenied/AccessDeniedPage'),
);

const unknownRoute: Route = {
  component: LazyAccessDeniedPage,
  hideInSidebar: true,
  id: 'unknown-route',
  path: '*',
};

export default unknownRoute;
