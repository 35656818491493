import PATH from '@models/constants/Path';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyMockSettingsPage = lazy(
  () => import('@pages/dashboard/mockSettings/MockSettingsPage'),
);

const mockSettingsRoute: Route = {
  children: [],
  component: LazyMockSettingsPage,
  hideInSidebar: true,
  hideSidebar: false,
  id: 'mock-settings',
  path: PATH.MockSettings.Detail,
  title: 'route.mockSettings.title',
};

export default mockSettingsRoute;
