import appConfig from '@appconf/config';
import { ErrorFallbackPage, LoadingView } from '@procivis/react-components';
import { ErrorBoundary } from '@sentry/react';
import { isSentryAvailable } from '@services/error/Sentry';
import React, { FC, PropsWithChildren, Suspense, useCallback } from 'react';
import { Route } from 'react-router-dom';
import RouteModel from 'src/models/types/Routes';

export const lazyOutletRouteFromRoute = (route: RouteModel) => (
  <Route
    element={
      <LazyRouteElement key={route.id}>
        <route.component />
      </LazyRouteElement>
    }
    key={route.id}
    path={route.path}
  />
);

interface LazyRouteElementProps extends PropsWithChildren {}

const LazyRouteElement: FC<LazyRouteElementProps> = ({ children }) => {
  const getErrorFallbackPage = useCallback(
    (errorData: {
      componentStack: string;
      error: Error;
      eventId: string;
      resetError(): void;
    }) => (
      <ErrorFallbackPage
        appSlug={appConfig.slug}
        isSentryAvailable={isSentryAvailable}
        {...errorData}
      />
    ),
    [],
  );

  return (
    <ErrorBoundary fallback={getErrorFallbackPage}>
      <Suspense fallback={<LoadingView />}>{children}</Suspense>
    </ErrorBoundary>
  );
};

export default LazyRouteElement;
