import { DEFAULT_LANGUAGE } from '@models/constants/Language';
import { LANGUAGE_STORAGE_KEY } from '@models/constants/Storage';
import { LanguageStoreModel } from '@models/store/language/LanguageStoreModel';
import { StoreEnv } from '@store/Environment';

export const setupLanguageStore = (env: StoreEnv) => {
  return LanguageStoreModel.create(
    {
      currentLanguage:
        localStorage.getItem(LANGUAGE_STORAGE_KEY) ?? DEFAULT_LANGUAGE,
    },
    env,
  );
};
