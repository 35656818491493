import { PERSISTENT_AUTH_STORAGE_KEY } from '@models/constants/Storage';
import {
  NetworkStore,
  NetworkStoreModel,
  NetworkStoreSnapshot,
  NetworkStoreSnapshotIn,
} from '@models/store/network/NetworkStore';
import { getPersistedData, persistData } from '@store/Storage';
import { onSnapshot } from 'mobx-state-tree';

export const setupNetworkStore = () => {
  let networkStore: NetworkStore;
  const initData = {
    authToken: undefined,
    authTokenExpirationTimestamp: undefined,
    loginTimestamp: undefined,
  };

  try {
    const storedAuthData = getPersistedData<Partial<NetworkStoreSnapshotIn>>(
      PERSISTENT_AUTH_STORAGE_KEY,
    );
    const mergedData = { ...initData, ...storedAuthData };

    networkStore = NetworkStoreModel.create(mergedData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Couldn't load persistent auth state", e);
    networkStore = NetworkStoreModel.create(initData);
  }

  onSnapshot(networkStore, (snapshot) => {
    const persistableData: Partial<NetworkStoreSnapshot> = {
      authToken: snapshot.authToken,
      authTokenExpirationTimestamp: snapshot.authTokenExpirationTimestamp,
      loginTimestamp: snapshot.loginTimestamp,
    };

    persistData<Partial<NetworkStoreSnapshot>>(
      persistableData,
      PERSISTENT_AUTH_STORAGE_KEY,
    );
  });

  return networkStore;
};
