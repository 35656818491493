import { WarningOutlined } from '@ant-design/icons';
import { Card, Space } from '@procivis/react-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './AccessDeniedPage.module.css';

const AccessDeniedPage: FC = () => {
  const { t } = useTranslation();

  const header = (
    <Space direction="horizontal">
      <WarningOutlined />
      {t('common.accessDenied.cardTitle')}
    </Space>
  );

  return (
    <div className={styles.container} data-testid="access-denied">
      <Card className={styles.cardWrapper} title={header}>
        {t('common.accessDenied.cardContent')}
      </Card>
    </div>
  );
};

export default AccessDeniedPage;
