export const persistData = <T>(
  localStorageData: T,
  storageKey: string,
): void => {
  localStorage.setItem(storageKey, JSON.stringify(localStorageData));
};

export const getPersistedData = <T>(storageKey: string): T => {
  const storedData = localStorage.getItem(storageKey);

  return storedData ? JSON.parse(storedData) : {};
};
