import { LANGUAGE_STORAGE_KEY } from '@models/constants/Storage';
import { StoreEnv } from '@store/Environment';
import { i18nInitialization } from '@utils/i18n/i18n';
import {
  getEnv,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from 'mobx-state-tree';

export const LanguageStoreModel = types
  .model('LanguageStore', {
    currentLanguage: types.string,
  })
  // Sync Actions
  .actions((self) => ({
    setLanguage: (newLng: string) => {
      const i18n = getEnv<StoreEnv>(self).i18nextInstance;
      if (i18n.languages[0] !== newLng) {
        i18n.changeLanguage(newLng);
        localStorage.setItem(LANGUAGE_STORAGE_KEY, newLng);
      }

      if (self.currentLanguage !== newLng) {
        self.currentLanguage = newLng;
      }
    },
  }))
  // Async Actions
  .actions((self) => ({
    changeLanguage: async (newLng: string) => {
      const i18n = getEnv<StoreEnv>(self).i18nextInstance;
      await i18nInitialization(i18n);
      self.setLanguage(newLng); // Can only modify state from async action using sync action
    },
  }));

export interface LanguageStore extends Instance<typeof LanguageStoreModel> {}
export interface LanguageStoreSnapshotIn
  extends SnapshotIn<typeof LanguageStoreModel> {}
export interface LanguageStoreSnapshot
  extends SnapshotOut<typeof LanguageStoreModel> {}
