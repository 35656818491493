import { ExceptionOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyCredentialSchemaListPage = lazy(
  () =>
    import(
      '@pages/dashboard/credentialSchema/CredentialSchemaListPage/CredentialSchemaListPage.adapter'
    ),
);
const LazyCredentialSchemaDetailPage = lazy(
  () =>
    import(
      '@pages/dashboard/credentialSchema/CredentialSchemaDetailPage/CredentialSchemaDetailPage.adapter'
    ),
);
const LazyCredentialSchemaCreatePage = lazy(
  () =>
    import(
      '@pages/dashboard/credentialSchema/CredentialSchemaCreatePage/CredentialSchemaCreatePage.adapter'
    ),
);

const schemasRoute: Route = {
  children: [
    {
      component: LazyCredentialSchemaCreatePage,
      id: 'create-credential-schema',
      path: PATH.CredentialSchema.Create,
      requiredPermission: OrganisationPermission.CredentialSchemaCreate,
    },
    {
      component: LazyCredentialSchemaDetailPage,
      id: 'credential-schema-detail',
      path: PATH.CredentialSchema.Detail(':schemaId'),
      requiredPermission: OrganisationPermission.CredentialSchemaDetail,
    },
  ],
  component: LazyCredentialSchemaListPage,
  hideInSidebar: false,
  iconComponent: ExceptionOutlined,
  id: 'schema',
  path: PATH.CredentialSchema.List,
  requiredPermission: OrganisationPermission.CredentialSchemaList,
  title: 'route.credentialSchemas.title',
};

export default schemasRoute;
