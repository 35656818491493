import { CompassOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyDidListPage = lazy(
  () => import('@pages/dashboard/did/DidListPage/DidListPage.adapter'),
);

const LazyDidDetailsPage = lazy(
  () => import('@pages/dashboard/did/DidDetailsPage/DidDetailsPage.adapter'),
);
const LazyDidCreatePage = lazy(
  () => import('@pages/dashboard/did/DidCreatePage/CreateDidPage.adapter'),
);

const didRoute: Route = {
  children: [
    {
      component: LazyDidDetailsPage,
      id: 'did-detail',
      path: PATH.Did.Detail(':didId'),
      requiredPermission: OrganisationPermission.DidDetail,
    },
    {
      component: LazyDidCreatePage,
      id: 'did-create',
      path: PATH.Did.Create,
      requiredPermission: OrganisationPermission.DidCreate,
    },
  ],
  component: LazyDidListPage,
  hideInSidebar: false,
  iconComponent: CompassOutlined,
  id: 'did',
  path: PATH.Did.List,
  requiredPermission: OrganisationPermission.DidList,
  title: 'route.didList.title',
};

export default didRoute;
