import { ApiConfigStoreModel } from '@models/store/apiConfig/ApiConfigStore';
import { LanguageStoreModel } from '@models/store/language/LanguageStoreModel';
import { NetworkStoreModel } from '@models/store/network/NetworkStore';
import { OrganisationStoreModel } from '@models/store/organisation/OrganisationStore';
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const RootStoreModel = types
  .model('RootStore', {
    apiConfigStore: ApiConfigStoreModel,
    languageStore: LanguageStoreModel,
    networkStore: NetworkStoreModel,
    organisationStore: OrganisationStoreModel,
  })
  // Sync Actions
  .actions((self) => ({
    logout: () => {
      self.networkStore.logout();
      self.organisationStore.logout();
    },
  }));

// Store types
export interface IRootStore extends Instance<typeof RootStoreModel> {}
export interface IRootStoreSnapshotIn
  extends SnapshotIn<typeof RootStoreModel> {}
export interface IRootStoreSnapshot
  extends SnapshotOut<typeof RootStoreModel> {}
