export const CREDENTIAL_DETAIL_ISSUE_MODAL_PARAM = 'showIssueModal';
export const DETAILS_SHARE_MODAL_PARAM = 'showShareModal';
export const PROOF_REQUEST_DETAIL_REQUEST_MODAL_PARAM = 'showRequestModal';
export const SCHEMA_ID_PARAM = 'schemaId';
export const GENERATE_CSR_MODAL = 'csrModal';

const getPathWithQuery = (
  path: string,
  queryParams: Record<string, string | number | boolean | undefined>,
) => {
  const query = Object.entries(queryParams)
    .filter(([, value]) => value !== undefined)
    .map(
      ([param, value]) =>
        `${param}=${encodeURIComponent(value as string | number | boolean)}`,
    )
    .join('&');
  const pathSuffix = query ? `?${query}` : '';
  return `${path}${pathSuffix}`;
};

const PATH = {
  AppVersion: {
    Detail: '/version',
  },
  Auth: {
    ForgotPassword: '/forgot-password',
    Login: '/login',
    SetPassword: '/set-password',
  },
  CredentialSchema: {
    Create: '/schema/create',
    Detail: (schemaId: string, opts: { shareModalOpen?: boolean } = {}) =>
      getPathWithQuery(`/schema/${schemaId}`, {
        [DETAILS_SHARE_MODAL_PARAM]: opts?.shareModalOpen,
      }),
    List: '/schema',
  },
  Credentials: {
    Detail: (credentialId: string, opts: { issueModalOpen?: boolean } = {}) =>
      getPathWithQuery(`/credentials/${credentialId}`, {
        [CREDENTIAL_DETAIL_ISSUE_MODAL_PARAM]: opts.issueModalOpen,
      }),
    Issue: (opts: { schamaId?: string } = {}) =>
      getPathWithQuery('/credentials/issue', {
        [SCHEMA_ID_PARAM]: opts?.schamaId,
      }),
    List: '/credentials',
  },
  Did: {
    Create: '/did/create',
    Detail: (didId: string) => `/did/${didId}`,
    List: '/did',
  },
  Keys: {
    Create: '/keys/create',
    Details: (keyId: string) => `/keys/${keyId}`,
    List: (opts: { generateCSRModal?: string } = {}) =>
      getPathWithQuery(`/keys`, {
        [GENERATE_CSR_MODAL]: opts.generateCSRModal,
      }),
  },
  MockSettings: {
    Detail: '/mock-settings',
  },
  Profile: {
    Detail: '/my-account',
  },
  ProofRequest: {
    Create: (opts: { schamaId?: string } = {}) =>
      getPathWithQuery('/proof-request/create', {
        [SCHEMA_ID_PARAM]: opts?.schamaId,
      }),
    Detail: (
      proofRequestId: string,
      opts: { requestModalOpen?: boolean } = {},
    ) =>
      getPathWithQuery(`/proof-request/${proofRequestId}`, {
        [PROOF_REQUEST_DETAIL_REQUEST_MODAL_PARAM]: opts.requestModalOpen,
      }),
    List: '/proof-request',
  },
  ProofSchema: {
    Create: '/proof-schema/create',
    Detail: (proofSchemaId: string, opts: { shareModalOpen?: boolean } = {}) =>
      getPathWithQuery(`/proof-schema/${proofSchemaId}`, {
        [DETAILS_SHARE_MODAL_PARAM]: opts?.shareModalOpen,
      }),
    List: '/proof-schema',
  },
  Root: '/',
  TrustEntity: {
    Create: (trustAnchorId: string) =>
      `/trust-management/${trustAnchorId}/trust-entity/create/`,
    Details: (trustEntityId: string) =>
      `/trust-management/trust-entity/${trustEntityId}`,
  },
  TrustManagement: {
    Details: (trustAnchorId: string) => `/trust-management/${trustAnchorId}`,
    List: '/trust-management',
  },
} as const;

export default PATH;
